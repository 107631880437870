<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="printTitle_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-print-title-filter></app-print-title-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="printTitles"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import PrintTitleFilter from './PrintTitleFilter'
import DataTable from '../shared/DataTable'
import SiteService from '../../services/site/SiteService'
import ButtonCreate from '../shared/ButtonCreate'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'PrintTitleList',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('printTitle.list.id'),
          title: this.$t('printTitle.list.title'),
          name: this.$t('printTitle.list.name'),
          site: this.$t('printTitle.list.site')
        },
        actions: {
          detail: 'printTitle_detail',
          edit: 'printTitle_edit'
        },
        render: {
          site: function (data) {
            if (data) {
              return this.siteTitleById(data)
            }
          }.bind(this)
        }
      },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_PRINT_TITLE_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    printTitles () {
      return this.$store.getters['printTitle/list']
    },
    totalCount () {
      return this.$store.getters['printTitle/totalCount']
    },
    page () {
      return this.$store.getters['printTitle/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appPrintTitleFilter: PrintTitleFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getPrintTitles () {
      this.$store.dispatch('printTitle/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('printTitle/setPage', page)
      this.getPrintTitles()
    },
    siteTitleById (id) {
      return SiteService.getSiteTitle(id)
    }
  },
  mounted () {
    this.getPrintTitles()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
